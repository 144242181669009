<template>
  <v-card v-if="userCopy">
    <v-card-title>
      <div class="headline">Company details</div>
    </v-card-title>
    <v-card-text class="primary--text">
      <v-text-field
        class="mb-4"
        label="Company name"
        v-model="userCopy.business.company"
        hint="For internal use"
        persistent-hint
        v-validate="'required'"
        data-vv-as="Company name"
        data-vv-name="company"
        data-vv-scope="business"
        :error-messages="errors.collect('business.company')"
      ></v-text-field>

      <v-text-field
        class="mb-4"
        label="Display name"
        v-model="userCopy.business.display_name"
        hint="Company name for influencers - optional"
        persistent-hint
      ></v-text-field>

      <p class="mt-3">
        Tell the influencers who you are and what your mission is.
        The description is shown on all campaigns. Use the good story to
        convince the influencers!
      </p>
      <v-alert v-if="descriptionTooLong" type="error"
        >Description has to be under 1000 characters</v-alert
      >
      <simple-rich-text-input
        v-model="userCopy.business.description"
        :height="145"
        :maxlength="1000"
      ></simple-rich-text-input>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="primary"
        :disabled="descriptionTooLong"
        :loading="businessInfoLoading"
        @click="saveBusiness()"
        >Save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import SimpleRichTextInput from "@/components/inputs/SimpleRichTextInput";

export default {
  props: ["user"],
  components: {
    SimpleRichTextInput
  },
  data: () => ({
    userCopy: null,
    businessInfoLoading: false
  }),
  computed: {
    descriptionTooLong() {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = this.userCopy.business.description;
      return (
        (tmp.textContent || tmp.innerText || "").replace(/  +/g, " ").length >
        1000
      );
    }
  },
  methods: {
    ...mapActions("core/auth", ["setUser"]),
    ...mapActions("business", ["updateBusiness"]),
    saveBusiness() {
      this.businessInfoLoading = true;
      this.$validator.validateAll("business").then(value => {
        if (value) {
          let settings = {
            business_uuid: this.userCopy.business.uuid,
            company: this.userCopy.business.company,
            display_name: this.userCopy.business.display_name,
            description: this.userCopy.business.description
          };
          this.updateBusiness(settings).then(
            // eslint-disable-next-line no-unused-vars
            response => {
              const copy = this.cloneDeep(this.userCopy);
              this.setUser(copy);
              this.businessInfoLoading = false;
              this.setSnackSuccess("Company details saved");
            },
            error => {
              console.log(error);
              this.businessInfoLoading = false;
              this.setSnackError("Could not save company details");
            }
          );
        }
      });
    }
  },
  created() {
    this.userCopy = this.cloneDeep(this.user);
  }
};
</script>
