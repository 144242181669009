<template>
  <v-card v-if="userCopy">
    <v-card-title>
      <div>
        <div class="headline">Notifications</div>
        <span class="subtitle-1 light-grey">Email notifications</span>
      </div>
    </v-card-title>
    <v-card-text>
      <v-list subheader three-line>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox
              v-model="userCopy.user_settings.notification_email_campaign_invitation"
              @click="
                saveSetting(
                  'notification_email_campaign_invitation',
                  userCopy.user_settings.notification_email_campaign_invitation
                )
              "
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title
              >Campaign invitations/applications</v-list-item-title
            >
            <v-list-item-subtitle
              >Get notified when you're invited, accepted or rejected
              for a campaign</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-checkbox
              v-model="userCopy.user_settings.notification_email_campaign_start"
              @click="
                saveSetting(
                  'notification_email_campaign_start',
                  userCopy.user_settings.notification_email_campaign_start
                )
              "
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Campaign start</v-list-item-title>
            <v-list-item-subtitle
              >Get notified before a campaign starts</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-checkbox
              v-model="userCopy.user_settings.notification_email_campaign_end"
              @click="
                saveSetting(
                  'notification_email_campaign_end',
                  userCopy.user_settings.notification_email_campaign_end
                )
              "
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Campaign end</v-list-item-title>
            <v-list-item-subtitle
              >Get notified before a campaign ends, allowing you to change the
              campaigns unique links</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-checkbox
              v-model="userCopy.user_settings.notification_email_unread_chat_message"
              @click="
                saveSetting(
                  'notification_email_unread_chat_message',
                  userCopy.user_settings.notification_email_unread_chat_message
                )
              "
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Unread messages</v-list-item-title>
            <v-list-item-subtitle
              >Get notified when you have unread messages from brands</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox
              v-model="userCopy.user_settings.notification_email_new_sale"
              @click="
                saveSetting(
                  'notification_email_new_sale',
                  userCopy.user_settings.notification_email_new_sale
                )
              "
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>New sale</v-list-item-title>
            <v-list-item-subtitle
              >We send you an email, when you've received a new sale</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["user"],
  data: () => ({
    userCopy: null
  }),
  methods: {
    ...mapActions("core/auth", ["setUser"]),
    ...mapActions("core", ["updateUserSetting"]),
    saveSetting(field, value) {
      let settings = {
        key: field,
        value: value.toString()
      };
      this.updateUserSetting(settings).then(
        response => {
          this.userCopy.user_settings = response;
          const copy = this.cloneDeep(this.userCopy);
          this.setUser(copy);
          this.setSnackSuccess("Gemt");
        },
        () => {
          this.setSnackSuccess("Fejl");
        }
      );
    }
  },
  created() {
    this.userCopy = this.cloneDeep(this.user);
  }
};
</script>
