<template>
  <v-card v-if="userCopy">
    <v-card-title>
      <div class="w-100 d-flex justify-space-between">
        <div class="headline">Profile picture</div>
        <div
          v-if="socialSync"
          @click="syncAvatar"
          class="d-flex cursor-pointer"
          :class="{ 'animation-spin': syncLoading }"
        >
          <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">fab fa-instagram</v-icon>
            </template>
            <span>Import from Instagram</span>
          </v-tooltip>
        </div>
        <!-- socialSync feature -->
      </div>
    </v-card-title>
    <v-card-text>
      <div class="mt-4">
        <input
          v-show="false"
          type="file"
          accept="image/png, image/jpeg"
          ref="avatarUpload"
          :multiple="false"
          @change="changeAvatar"
        />
        <div class="d-flex justify-center align-center">
          <div
            class="avatar-wrapper border-round-xl"
            @drop="dropAvatar($event)"
            @dragover="allowDrop($event)"
          >
            <v-avatar color="secondary" size="128" class="">
              <img v-if="userAvatar" class="avatar-cover" :src="userAvatar" />
              <span class="accent--text title" v-else>
                {{ userCopy.first_name | first_letter }}
                {{ userCopy.last_name | first_letter }}
              </span>
            </v-avatar>
            <div
              class="avatar-dropzne border-round-xl"
              v-if="displayDropzone"
            >
              <div
                class="d-flex flex-column align-center justify-center primary--text"
              >
                <v-icon color="primary">fal fa-cloud-upload-alt</v-icon>
                <div class="mt-2">Drop here to upload</div>
              </div>
            </div>
            <div
              class="avatar-hover-effect border-round-xl"
              @click="triggerForm"
            >
              <v-icon v-if="!hoverDelete" color="white">fal fa-edit</v-icon>
              <v-icon color="white" size="32px" v-else>fal fa-times</v-icon>
            </div>
            <div
              v-if="userAvatar && userCopy.avatar_url"
              class="delete-avatar error border-round-xl cursor-pointer"
              @click="showConfirmDelete = true"
              @mouseover="hoverDelete = true"
              @mouseout="hoverDelete = false"
            >
              <v-icon color="white">fal fa-times</v-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center my-5">
        <div class="subtitle-1 primary--text">
          <span class="mr-2">
            <v-icon color="primary" small>fal fa-paperclip</v-icon>
          </span>
          <span>Choose new picture</span>
        </div>
        <div class="subtitle-2 greytxt--text" v-if="$vuetify.breakpoint.mdAndUp">
          Or drag and drop
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="primary"
        @click="saveUserAvatar()"
        :loading="saveAvatarLoading"
      >
        Save image
      </v-btn>
    </v-card-actions>
    <!-- confirm delete dialog -->
    <v-dialog max-width="400px" v-model="showConfirmDelete">
      <v-card>
        <v-card-title>
          <div class="headline mb-3">Delete avatar</div>
        </v-card-title>
        <v-card-text>
          <div>
            Are you sure you want to remove the image?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showConfirmDelete = false">Cancel</v-btn>
          <v-btn
            color="error"
            :loading="deleteAvatarLoading"
            @click="deleteAvatar()"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import first_letter from "@/helpers/filters/first_letter";

export default {
  props: ["user", "socialSync"],
  filters: {
    first_letter
  },
  data: () => ({
    userCopy: null,
    userAvatar: null,
    file: null,
    saveAvatarLoading: false,
    deleteAvatarLoading: false,
    syncLoading: false,
    showConfirmDelete: false,
    hoverDelete: false,
    displayDropzone: false
  }),
  methods: {
    ...mapActions("core/auth", ["setUser"]),
    ...mapActions("core", [
      "uploadUserAvatar",
      "deleteUserAvatar",
      "importInfluencerAvatar"
    ]),
    saveUserAvatar() {
      this.saveAvatarLoading = true;
      let params = {
        image: this.file
      };
      this.uploadUserAvatar(params).then(
        // eslint-disable-next-line no-unused-vars
        response => {
          this.userCopy.avatar_url = response.avatar_url;
          this.userAvatar = response.avatar_url;
          this.setSnackSuccess("Profile details saved");
          const copy = this.cloneDeep(this.userCopy);
          this.setUser(copy);
          this.saveAvatarLoading = false;
        },
        () => {
          this.setSnackError("Could not save profile details");
          this.saveAvatarLoading = false;
        }
      );
    },
    deleteAvatar() {
      this.deleteAvatarLoading = true;
      this.deleteUserAvatar().then(
        // eslint-disable-next-line no-unused-vars
        response => {
          this.userCopy.avatar_url = null;
          this.userAvatar = null;
          this.file = null;
          this.setSnackSuccess("Profile details saved");
          const copy = this.cloneDeep(this.userCopy);
          this.setUser(copy);
          this.deleteAvatarLoading = false;
          this.showConfirmDelete = false;
        },
        () => {
          this.setSnackError("Could not save profile details");
          this.deleteAvatarLoading = false;
        }
      );
    },
    syncAvatar() {
      this.syncLoading = true;
      let params = {
        influencer_channel_uuid: this.userCopy.influencer.channels[0].uuid
      };
      this.importInfluencerAvatar(params).then(
        // eslint-disable-next-line no-unused-vars
        response => {
          this.userCopy.avatar_url = response.avatar_url;
          this.userAvatar = response.avatar_url;
          this.setSnackSuccess("Profile details saved");
          const copy = this.cloneDeep(this.userCopy);
          this.setUser(copy);
          setTimeout(() => {
            this.syncLoading = false;
          }, 1600);
        },
        errors => {
          this.setSnackError(errors[0].errors[0].message[0]);
          setTimeout(() => {
            this.syncLoading = false;
          }, 1600);
        }
      );
    },
    triggerForm() {
      this.$refs.avatarUpload.click();
    },
    changeAvatar(event) {
      const image = event.target.files[0];
      if (image) {
        this.file = image;
        this.userAvatar = URL.createObjectURL(image);
      }
    },
    allowDrop(event) {
      event.preventDefault();
    },
    dropAvatar(event) {
      event.preventDefault();
      const image = event.dataTransfer.files[0];
      if (
        image &&
        (image.type === "image/jpeg" || image.type === "image/png")
      ) {
        this.file = image;
        this.userAvatar = URL.createObjectURL(image);
      } else {
        this.setSnackError("Only jpg / png images allowed!");
      }
    },
    dragover(event) {
      const dt = event.dataTransfer;
      if (this.displayDropzone) {
        return null;
      }
      if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files'))) {
        this.displayDropzone = true;
      }
    },
    mouseout() {
      this.displayDropzone = false;
    }
  },
  created() {
    this.userCopy = this.cloneDeep(this.user);
    // preload avatar if exist
    if (this.user.avatar_url) {
      this.userAvatar = this.user.avatar_url;
    }
    document.addEventListener("dragover", this.dragover);
    document.addEventListener("mouseout", this.mouseout);
  },
  beforeDestroy() {
    document.removeEventListener("dragover", this.dragover);
    document.removeEventListener("mouseout", this.mouseout);
  }
};
</script>
<style lang="scss" scoped>
.avatar-wrapper {
  position: relative;
  border-radius: 100%;
  .avatar-cover {
    object-fit: cover;
  }
  .avatar-hover-effect {
    background: rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .avatar-dropzne {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border: 2px dashed #013138;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 16px;
    text-align: center;
  }
  .delete-avatar {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
  }
  &:hover {
    .avatar-hover-effect {
      opacity: 1;
      cursor: pointer;
    }
  }
}
</style>
