<template>
  <v-card>
    <v-card-title>
      <div>
        <div class="headline">Change password</div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-text-field
        label="Current password"
        :prepend-icon="showPassword ? 'fal fa-eye' : 'fal fa-eye-slash'"
        :type="showPassword ? 'text' : 'password'"
        @click:prepend="showPassword = !showPassword"
        v-model="passwords.old_password"
        v-validate="'required'"
        data-vv-as="Current password"
        data-vv-name="old_password"
        data-vv-scope="passwords"
        :error-messages="validation.old_password ? validation.old_password : errors.collect('passwords.password')"
        @keyup="validation.old_password = null"
      ></v-text-field>
      <v-text-field
        label="New password"
        :prepend-icon="showPassword ? 'fal fa-eye' : 'fal fa-eye-slash'"
        :type="showPassword ? 'text' : 'password'"
        @click:prepend="showPassword = !showPassword"
        v-model="passwords.new_password"
        v-validate="'required'"
        data-vv-as="New password"
        data-vv-name="new_password"
        data-vv-scope="passwords"
        :error-messages="validation.password ? validation.password : errors.collect('passwords.new_password')"
        @keyup="validation.password = null"
        ref="new_password"
      ></v-text-field>
      <v-text-field
        label="Repeat new password"
        :prepend-icon="showPassword ? 'fal fa-eye' : 'fal fa-eye-slash'"
        :type="showPassword ? 'text' : 'password'"
        @click:prepend="showPassword = !showPassword"
        v-model="passwords.new_password_confirmation"
        v-validate="'required|confirmed:new_password'"
        data-vv-as="Repeat new password"
        data-vv-name="new_password_confirmation"
        data-vv-scope="passwords"
        :error-messages="validation.password_confirmation ? validation.password_confirmation : errors.collect('passwords.new_password_confirmation')"
        @keyup="validation.password_confirmation = null"
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="updatePassword()"
        >Update password</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["user"],
  data: () => ({
    showPassword: false,
    passwords: {
      old_password: "",
      new_password: "",
      new_password_confirmation: ""
    },
    validation: {
      old_password: null,
      password: null,
      password_confirmation: null
    }
  }),
  methods: {
    ...mapActions("core", ["updateUserPassword"]),
    updatePassword() {
      this.$validator.validateAll("passwords").then(value => {
        if (value) {
          let settings = {
            password: this.passwords.old_password,
            new_password: this.passwords.new_password_confirmation
          };

          this.updateUserPassword(settings)
            .then(() => {
              this.setSnackSuccess("Your password was updated");
              this.$emit("success");
              this.passwords = {};
            })
            .then(() => {
              this.$validator.reset();
            })
            .catch(errors => {
              const arr = this.$errorHandler.getValidationErrors(errors);
              this.validation = {
                old_password: this.$errorHandler.getErrorMessage(arr, "old_password"),
                password: this.$errorHandler.getErrorMessage(arr, "password"),
                password_confirmation: this.$errorHandler.getErrorMessage(arr, "password_confirmation"),
              };
              this.setSnackError("Could not update your password");
            });
        }
      });
    }
  },
  created() {
    this.$validator.localize("da", this.validatorDictionary);
  }
};
</script>
