var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userCopy)?_c('v-card',[_c('v-card-title',[_c('div',[_c('div',{staticClass:"headline"},[_vm._v("Notifications")]),_c('span',{staticClass:"subtitle-1 light-grey"},[_vm._v("Email notifications")])])]),_c('v-card-text',[_c('v-list',{attrs:{"subheader":"","three-line":""}},[_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{on:{"click":function($event){return _vm.saveSetting(
                'notification_email_campaign_invitation',
                _vm.userCopy.user_settings.notification_email_campaign_invitation
              )}},model:{value:(_vm.userCopy.user_settings.notification_email_campaign_invitation),callback:function ($$v) {_vm.$set(_vm.userCopy.user_settings, "notification_email_campaign_invitation", $$v)},expression:"userCopy.user_settings.notification_email_campaign_invitation"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Campaign invitations/applications")]),_c('v-list-item-subtitle',[_vm._v("Get notified when you're invited, accepted or rejected for a campaign")])],1)],1),_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{on:{"click":function($event){return _vm.saveSetting(
                'notification_email_campaign_start',
                _vm.userCopy.user_settings.notification_email_campaign_start
              )}},model:{value:(_vm.userCopy.user_settings.notification_email_campaign_start),callback:function ($$v) {_vm.$set(_vm.userCopy.user_settings, "notification_email_campaign_start", $$v)},expression:"userCopy.user_settings.notification_email_campaign_start"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Campaign start")]),_c('v-list-item-subtitle',[_vm._v("Get notified before a campaign starts")])],1)],1),_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{on:{"click":function($event){return _vm.saveSetting(
                'notification_email_campaign_end',
                _vm.userCopy.user_settings.notification_email_campaign_end
              )}},model:{value:(_vm.userCopy.user_settings.notification_email_campaign_end),callback:function ($$v) {_vm.$set(_vm.userCopy.user_settings, "notification_email_campaign_end", $$v)},expression:"userCopy.user_settings.notification_email_campaign_end"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Campaign end")]),_c('v-list-item-subtitle',[_vm._v("Get notified before a campaign ends, allowing you to change the campaigns unique links")])],1)],1),_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{on:{"click":function($event){return _vm.saveSetting(
                'notification_email_unread_chat_message',
                _vm.userCopy.user_settings.notification_email_unread_chat_message
              )}},model:{value:(_vm.userCopy.user_settings.notification_email_unread_chat_message),callback:function ($$v) {_vm.$set(_vm.userCopy.user_settings, "notification_email_unread_chat_message", $$v)},expression:"userCopy.user_settings.notification_email_unread_chat_message"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Unread messages")]),_c('v-list-item-subtitle',[_vm._v("Get notified when you have unread messages from brands")])],1)],1),_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{on:{"click":function($event){return _vm.saveSetting(
                'notification_email_new_sale',
                _vm.userCopy.user_settings.notification_email_new_sale
              )}},model:{value:(_vm.userCopy.user_settings.notification_email_new_sale),callback:function ($$v) {_vm.$set(_vm.userCopy.user_settings, "notification_email_new_sale", $$v)},expression:"userCopy.user_settings.notification_email_new_sale"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("New sale")]),_c('v-list-item-subtitle',[_vm._v("We send you an email, when you've received a new sale")])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }