<template>
  <v-card v-if="userCopy">
    <v-card-title>
      <div>
        <div class="headline">Profile details</div>
        <span class="subtitle-1 light-grey"></span>
      </div>
    </v-card-title>
    <v-card-text>
      <v-text-field
        label="First name"
        v-model="userCopy.first_name"
        v-validate="'required'"
        data-vv-as="First name"
        data-vv-name="first_name"
        data-vv-scope="profile"
        :error-messages="validation.first_name ? validation.first_name : errors.collect('profile.first_name')"
        @keyup="validation.first_name = null"
      ></v-text-field>

      <v-text-field
        label="Last name"
        v-model="userCopy.last_name"
        v-validate="'required'"
        data-vv-as="Last name"
        data-vv-name="last_name"
        data-vv-scope="profile"
        :error-messages="validation.last_name ? validation.last_name : errors.collect('profile.last_name')"
        @keyup="validation.last_name = null"
      ></v-text-field>

      <v-text-field
        label="Email"
        v-model="userCopy.email"
        v-validate="'required|email'"
        data-vv-as="Email"
        data-vv-name="email"
        data-vv-scope="profile"
        :error-messages="validation.email ? validation.email : errors.collect('profile.email')"
        @keyup="validation.email = null"
      ></v-text-field>

      <v-text-field
        v-if="changePhone"
        label="Phone number"
        v-model="userCopy.phone"
        v-validate="'required|min:8|max:8|numeric|excluded:00000000'"
        data-vv-as="Phone number."
        data-vv-name="phone"
        data-vv-scope="profile"
        name="Phone number"
        type="tel"
        :error-messages="validation.phone ? validation.phone : errors.collect('profile.phone')"
        @keyup="validation.phone = null"
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="primary"
        @click="saveProfile()"
        :loading="saveProfileLoading"
      >
        Save profile
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["user", "changePhone"],
  data: () => ({
    userCopy: null,
    saveProfileLoading: false,
    validation: {
      email: null,
      phone: null,
      first_name: null,
      last_name: null
    }
  }),
  methods: {
    ...mapActions("core/auth", ["setUser"]),
    ...mapActions("core", ["updateUserData"]),
    saveProfile() {
      this.saveProfileLoading = true;
      let user = {
        first_name: this.userCopy.first_name,
        last_name: this.userCopy.last_name,
        email: this.userCopy.email
      };
      // check if moderator.
      if (this.userCopy.moderator) {
        user.uuid = this.userCopy.moderator.user.uuid;
      } else {
        user.uuid = this.userCopy.uuid;
      }
      // check if influencer
      if (this.userCopy.influencer) {
        user.phone = this.userCopy.phone;
      }
      this.updateUserData(user).then(
        // eslint-disable-next-line no-unused-vars
        response => {
          this.setSnackSuccess("Profile details saved");
          const copy = this.cloneDeep(this.userCopy);
          this.setUser(copy);
          this.saveProfileLoading = false;
        },
        errors => {
          const arr = this.$errorHandler.getValidationErrors(errors);
          this.validation = {
            email: this.$errorHandler.getErrorMessage(arr, "email"),
            phone: this.$errorHandler.getErrorMessage(arr, "phone"),
            first_name: this.$errorHandler.getErrorMessage(arr, "first_name"),
            last_name: this.$errorHandler.getErrorMessage(arr, "last_name")
          };
          this.setSnackError("Could not save profile details");
          this.saveProfileLoading = false;
        }
      );
    }
  },
  created() {
    this.userCopy = this.cloneDeep(this.user);
  }
};
</script>
