<template>
  <v-card v-if="userCopy">
    <v-card-title>
      <div>
        <div class="headline">Notifications</div>
        <span class="subtitle-1 light-grey">Email notifications</span>
      </div>
    </v-card-title>
    <v-card-text>
      <v-list subheader two-line>
        <!--<v-subheader>Hangout notifications</v-subheader>-->

        <v-list-item>
          <v-list-item-action>
            <v-checkbox
              v-model="
                userCopy.user_settings.notification_email_campaign_invitation
              "
              @click="
                saveSetting(
                  'notification_email_campaign_invitation',
                  userCopy.user_settings.notification_email_campaign_invitation
                )
              "
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Campaign applications</v-list-item-title>
            <v-list-item-subtitle>
              Get notified when influencers apply to your campaigns
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["user"],
  data: () => ({
    userCopy: null,
  }),
  methods: {
    ...mapActions("core/auth", ["setUser"]),
    ...mapActions("core", ["updateUserSetting"]),
    saveSetting(field, value) {
      let settings = {
        key: field,
        value: value.toString(),
      };
      this.updateUserSetting(settings).then(
        (response) => {
          this.userCopy.user_settings = response;
          const copy = this.cloneDeep(this.userCopy);
          this.setUser(copy);
          this.setSnackSuccess("Gemt");
        },
        () => {
          this.setSnackSuccess("Fejl");
        }
      );
    },
  },
  created() {
    this.userCopy = this.cloneDeep(this.user);
  },
};
</script>
