<template>
  <v-container fluid grid-list-xl class="p-0">
    <v-layout wrap row>
      <!-- upload avatar card -->
      <v-flex xs12 sm6 md6>
        <update-user-avatar-card
          :user="user"
          :socialSync="isInfluencer"
        ></update-user-avatar-card>
      </v-flex>
      <!-- change password card -->
      <v-flex xs12 sm6 md6>
        <change-password-card :user="user"></change-password-card>
      </v-flex>
      <!-- main info  card-->
      <v-flex xs12 sm6 md6 >
        <update-user-info-card
          :user="user"
          :changePhone="isInfluencer"
        ></update-user-info-card>
        <business-notification-settings-card
          v-if="isBusiness || isAgency"
          :user="user"
          class="mt-6"
        ></business-notification-settings-card>
      </v-flex>
      <!-- business info card -->
      <v-flex xs12 sm6 md6 v-if="isBusiness">
        <update-business-info-card :user="user"></update-business-info-card>
      </v-flex>
      <!-- influencer notifications -->
      <v-flex xs12 sm6 md6 v-if="isInfluencer">
        <influencer-notification-settings-card
          :user="user"
        ></influencer-notification-settings-card>
      </v-flex>
      <!-- influencer country -->
      <!-- <v-flex xs12 sm6 md6 v-if="isInfluencer">
        <update-influencer-country-card
          :user="user"
        ></update-influencer-country-card>
      </v-flex> -->
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import ChangePasswordCard from "./cards/ChangePasswordCard";
import UpdateUserInfoCard from "./cards/UpdateUserInfoCard";
import InfluencerNotificationSettingsCard from "./cards/InfluencerNotificationSettingsCard";
import BusinessNotificationSettingsCard from "./cards/BusinessNotificationSettingsCard";
import UpdateBusinessInfoCard from "./cards/UpdateBusinessInfoCard";
import UpdateUserAvatarCard from "./cards/UpdateUserAvatarCard";
// import UpdateInfluencerCountryCard from "./cards/UpdateInfluencerCountryCard";

export default {
  components: {
    ChangePasswordCard,
    UpdateUserInfoCard,
    UpdateBusinessInfoCard,
    UpdateUserAvatarCard,
    InfluencerNotificationSettingsCard,
    BusinessNotificationSettingsCard,
    // UpdateInfluencerCountryCard
  },
  data: () => ({
    userProfile: {},
    saveProfileLoading: false
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapGetters("core/auth", [
      "isBusiness",
      "isInfluencer",
      "isModerator",
      "isAgency"
    ])
  },
  created() {
    this.$validator.localize("da", this.validatorDictionary);
  }
};
</script>
